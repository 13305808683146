
.header {
    background-color: #a90303;
}

.testing {
    margin-top: 10px;
}

.testing a {
    margin-right: 20px;
}

.main {
    margin-bottom: 150px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 105px;
    background-color: lightgray;
}

.footer p {
    margin: 0;
}

.page-not-found h2 {
    font-size: 10em;
    color: gray;
}

.page-not-found .links a {
    padding: 10px 20px;
    margin: 10px 20px;
}