.lead-statement {
    font-size: 1.375em;
}

.sub-heading {
    font-size: 1.25rem;
}

.back-button {
    margin-left: -15px;
    margin-bottom: 10px;
}

.back-button svg {
    margin-top: -5px;
}

.symptoms-form {
    margin-left: 50px;
}

.symptoms-form .form-check {
    margin-bottom: 20px;
}

.symptoms-form input[type=checkbox] {
    width: 36px;
    height: 36px;
}

.symptoms-form label {
    margin-left: 30px;
}

.symptoms-form label p {
    margin: 0;
}

.symptoms-form label p b {
    font-size: 1.25em;
}

.result-top {
    color: white;
    padding: 20px;
}

.result-good {
    background-color: rgb(17, 136, 71);
}

.result-bad {
    background-color: rgb(216, 26, 33);
}

.result p {
    font-size: 1.25em;
}

.test {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    background-color: white;
}

.test p {
    margin-right: 20px;
}